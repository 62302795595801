@tailwind base;
@tailwind components;
@tailwind utilities;

@import "overwrite.css";

html {
  scroll-behavior: smooth;
}

label.main-form {
  @apply block mb-2 text-lg font-bold text-grey-800;
}

.btn-primary {
  @apply flex items-center justify-center text-white transition duration-300 ease-in-out border-2 border-brown-400 bg-magenta hover:cursor-pointer;
}

.btn-primary:disabled,
.btn-primary--disabled {
  @apply pointer-events-none bg-grey-100 text-grey-300 border-grey-100;
}

.btn-outline {
  @apply border-2 border-grey-300 text-grey-600;
}

.btn-outline-light {
  @apply border-2 border-brown-600 text-brown-600;
}

.btn-secondary {
  @apply font-bold border text-grey-600 border-grey-400;
}

.input-checked-check:checked + .label-checked-check {
  @apply text-white bg-grey-800 border-grey-800;
}
.input-checked-check:checked + .label-checked-check .svg-label-checked-check {
  @apply flex;
}

.input-checked-check:checked + .label-checked-check .svg-label-checked-uncheck {
  @apply hidden;
}

@layer components {
  .absolute-center-x {
    @apply absolute transform -translate-x-1/2 left-1/2;
  }
  .absolute-center-y {
    @apply absolute transform -translate-y-1/2 top-1/2;
  }
  .absolute-center-xy {
    @apply absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .isHidden {
    @apply invisible w-0 h-0 opacity-0 pointer-events-none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent;
  -webkit-text-fill-color: #293042 !important;
}

.br-2x,
.br-3x {
  display: block;
  content: "";
}
.br-2x {
  margin-top: 0.6rem;
}

.br-3x {
  margin-top: 1.4rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.scrollBar::-webkit-scrollbar {
  display: none !important;
}

.scrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.form-container {
  @apply w-full px-4 py-8 mx-auto bg-white md:py-10 shadow-3xl sm:px-6 md:px-12;
}

.form-button-container {
  @apply flex flex-col flex-wrap justify-between mt-10 mb-8 md:mt-16 xxs:flex-row xxs:justify-between;
}

.form-button-container button {
  @apply text-lg xxs:min-w-[150px] whitespace-nowrap;
}

input::placeholder {
  @apply text-base xs:text-lg !important;
}

@keyframes slideIn {
  from {
    width: 0%;
  }
  to {
    width: var(--firstBlockWidth, 0%);
  }
}

.timeLivingHome {
  animation: slideIn 4500ms;
}

.placeholder-text-lg::placeholder {
  @apply text-lg !important;
}

.placeholder-text-32::placeholder {
  @apply text-[32px] text-[#525969] !important;
}

.placeholder-text-26::placeholder {
  @apply text-[26px] text-[#525969] !important;
}

@keyframes wipe-left {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Define wipe-right animation */
@keyframes wipe-right {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Define drop-from-top animation */
@keyframes drop-from-top {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Define flip-up animation */
@keyframes flip-up {
  from {
    opacity: 0;
    transform: translateY(30%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes flip-up2 {
  from {
    opacity: 0;
    transform: translateY(30%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply animation to the element */
.animate-wipe-left {
  animation: wipe-left 0.5s ease-in-out;
}

.animate-wipe-right {
  animation: wipe-right 0.5s ease-in-out;
}

.animate-drop-from-top {
  animation: drop-from-top 0.5s ease-in-out;
}

.animate-flip-up {
  animation: flip-up 0.6s ease-in-out;
}
.animate-flip-up2 {
  animation: flip-up2 0.6s ease-in-out;
}

@media (min-width: 768px) {
  .second-item-vertical-offset > :nth-of-type(2n) {
    margin-top: 5rem;
  }

  .second-item-vertical-offset > :nth-of-type(2n-1) {
    margin-top: -2rem;
  }
}
